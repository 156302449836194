import style from "./CourseProgressVerticalCard.module.css";
import { Link } from "react-router-dom";

import CourseCategory from "../courseCategory/CourseCategory";
import Icon from "@/ui/icon/Icon";

function CourseProgressVerticalCard({ course, active }) {
	const count = 2;

	return (
		<div className={style.cardHolder}>
			<div
				className={`${style.card} ${active ? style.active : ""}`}
				style={{ "--background": `url(${course.thumbnail})` }}
			>
				<div className={style.header}>
					<h3>{course.name}</h3>
				</div>
				<div className={style.body}>
					<p>
						Here comes the sun doo doo doo doo, here comes the sun.
						And I say it's all right.
					</p>
				</div>
				<div className={style.footer}>
					<div className={style.otherInfo}>
						<div className={style.authorHolder}>
							<div className={style.authors}>
								{course.lecturers.map((lecturer) => (
									<img
										key={lecturer.name}
										className={style.author}
										src={lecturer.picture_url}
									></img>
								))}
							</div>

							<span title={course.lecturers[0]?.name}>
								{course?.lecturers?.length > 0
									? course.lecturers[
											course.lecturers.length - 1
									  ]?.name
									: "Nema instrukotra"}
							</span>
						</div>
						<CourseCategory
							className={style.category}
							course={course}
						/>
					</div>
					{active && (
						<div className={style.progressHolder}>
							<div className={style.progressStats}>
								<span>Cjelina</span>
								<span>{count}</span>
								<div className={style.count}>
									<Icon icon="fa-slash-forward" />
									<span>{course.module_count}</span>
									<span className={style.sep}></span>
									<span className={style.timestamp}>
										24 min
									</span>
								</div>
							</div>
							<div className={style.progress}>
								{Array.from(
									{ length: course.module_count },
									(_, i) => (
										<div
											key={i}
											className={`${style.bar} ${
												i < count ? style.active : ""
											}`}
										></div>
									)
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			{active && (
				<div className={style.continueWatching}>
					<Link
						className={style.button}
						to={`/courses/${course.slug}`}
					>
						<span>nastavi gledati</span>
						<div className={style.icoHolder}>
							<Icon icon="fa-arrow-right-long" />
						</div>
					</Link>
				</div>
			)}
		</div>
	);
}

export default CourseProgressVerticalCard;
