export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function cleanReferralCode(code) {
	if (!code) return code;

	return code
		.replace(/[^a-zA-Z0-9]/g, "")
		.toLowerCase()
		.substring(0, 8);
}

export function secondsToHoursMinutes(seconds) {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	return hours <= 0 ? `${minutes}m` : `${hours}h ${minutes}m`;
}

export function secondsToMinutesSeconds(seconds) {
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = seconds % 60;
	return minutes <= 0
		? `${remainingSeconds}s`
		: `${minutes}m ${remainingSeconds}s`;
}

export function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}

export function timeAgo(timestamp) {
	const now = Date.now();
	const secondsAgo = Math.floor((now - timestamp) / 1000);

	if (secondsAgo < 0) {
		return "manje od minute";
	}

	const intervals = [
		{
			labels: { singular: "godinu", dual: "godine", plural: "godina" },
			seconds: 365 * 24 * 60 * 60,
		},
		{
			labels: { singular: "mjesec", dual: "mjeseca", plural: "mjeseci" },
			seconds: 30 * 24 * 60 * 60,
		},
		{
			labels: { singular: "sedmicu", dual: "sedmice", plural: "sedmica" },
			seconds: 7 * 24 * 60 * 60,
		},
		{
			labels: { singular: "dan", dual: "dana", plural: "dana" },
			seconds: 24 * 60 * 60,
		},
		{
			labels: { singular: "sat", dual: "sata", plural: "sati" },
			seconds: 60 * 60,
		},
		{
			labels: { singular: "minutu", dual: "minute", plural: "minuta" },
			seconds: 60,
		},
	];

	for (const interval of intervals) {
		const count = Math.floor(secondsAgo / interval.seconds);
		if (count >= 1) {
			const label = getLabel(count, interval.labels);
			return `prije ${count} ${label}`;
		}
	}

	return "manje od minute";
}

export function getLabel(count, labels) {
	const lastDigit = count % 10;
	const lastTwoDigits = count % 100;

	if (lastDigit === 1 && lastTwoDigits !== 11) {
		return labels.singular;
	} else if (
		[2, 3, 4].includes(lastDigit) &&
		![12, 13, 14].includes(lastTwoDigits)
	) {
		return labels.dual;
	} else {
		return labels.plural;
	}
}

export function formatDate(date) {
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	return `${day}.${month}.${year}`;
}

export function removeCjelina(input) {
	// Handle "1.1 Uvod" -> "Uvod"
	let regex1 = /^\d+(\.\d+)*\.?\s+(.*)$/;
	if (regex1.test(input)) {
		return input.replace(regex1, "$2");
	}

	// Handle "Cjelina 1: Bla bla" -> "Bla bla"
	let regex2 = /^Cjelina\s+\d+:\s+(.*)$/;
	if (regex2.test(input)) {
		return input.replace(regex2, "$1");
	}

	// Return the original string if no match
	return input;
}
