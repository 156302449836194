import { useApiGet } from "@/hooks/useApi";
import { getRecentCourses } from "../api";

export function useGetRecentCourses() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["courses", "list", "recent"],
		fn: getRecentCourses,
	});
	const { courses } = data || { courses: [] };

	return { data: courses, isPending, isSuccess, isError, error };
}
