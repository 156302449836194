import UmamiContext from "@/contexts/UmamiContext";
import { setupUmami } from "@/hooks/setupUmami";
import { Outlet } from "react-router-dom";

export default function UmamiWrapper() {
	const umamiData = setupUmami();

	return (
		<UmamiContext.Provider value={umamiData}>
			<Outlet />
		</UmamiContext.Provider>
	);
}
