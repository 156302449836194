import style from "./PathProgressBar.module.css";

import Icon from "@/ui/icon/Icon";

function PathProgressBar({ currentCourse, coursesInPath }) {
	return (
		<div className={style.progressBar}>
			<div className={style.header}>
				<span className={style.current}>{currentCourse}</span>
				<span className={style.total}>
					<Icon iconType="fa-thin" icon="fa-slash-forward" />
					{coursesInPath} courses completed
				</span>
			</div>
			<div className={style.progress}>
				{Array.from({ length: coursesInPath }, (_, i) => (
					<div
						key={i}
						className={`${style.bar} ${
							i < currentCourse ? style.active : ""
						}`}
					></div>
				))}
			</div>
		</div>
	);
}

export default PathProgressBar;
