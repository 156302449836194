import style from "./userProfile.module.css";
import { useUser } from "@/features/auth/hooks/useUser";
import { Link, useNavigate } from "react-router-dom";

import Icon from "@/ui/icon/Icon";
import { useQueryClient } from "@tanstack/react-query";
import { AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME } from "@/utils/constants";

function userProfile() {
	const { user } = useUser();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	function logOut() {
		localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME);
		queryClient.clear();
		navigate("/login");
	}

	return (
		<div className={style.profile}>
			<div className={style.imgHolder}>
				<img src={user?.safe_picture_url} alt="Profile" />
			</div>
			<span>{user?.username}</span>
			<div className={style.nav}>
				<Link to="/settings/" className={style.row}>
					<Icon icon="fa-cog" />
					Postavke
				</Link>
				<Link to="/support" className={style.row}>
					<Icon icon="fa-question-circle" />
					Zatraži pomoć
				</Link>
				<hr />
				<div onClick={logOut} className={style.row}>
					Odjavite se
				</div>
			</div>
		</div>
	);
}

export default userProfile;
