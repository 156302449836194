import React, { useEffect, useState } from "react";

import style from "./SearchFilter.module.css";

import Icon from "@/ui/icon/Icon";

import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation } from "swiper/modules";

function SearchFilter({ searchResults, activeCategory, setActiveCategory }) {
	const getCount = (type) => {
		if (type == "sve") return 1;

		return searchResults.reduce(
			(acc, curr) =>
				curr.type === type ? acc + curr.content.length : acc,
			0
		);
	};

	const categories = [
		{ icon: "", name: "Sve", type: "sve" },
		{ icon: "fa-chalkboard", name: "kursevi", type: "kursevi" },
		{ icon: "fa-film", name: "videi", type: "video" },
		{ icon: "fa-question", name: "kvizovi", type: "kvizovi" },
		{ icon: "fa-scribble", name: "putanje", type: "path" },
	].map((category) => ({
		...category,
		count: category.type ? getCount(category.type) : undefined,
	}));

	return (
		<Swiper
			direction="horizontal"
			slidesPerView="auto"
			slidesPerGroup={1}
			pagination={{ clickable: true }}
			scrollbar={{
				draggable: true,
			}}
			navigation={{
				nextEl: `.custom-next`,
				prevEl: `.custom-prev`,
			}}
			mousewheel={true}
			modules={[Navigation]}
			className={style.content}
		>
			{categories.map((category, index) => (
				<SwiperSlide
					key={index}
					className={`
						${style.category}		
						${
							activeCategory === category.type
								? style.active
								: category.count === 0
								? style.disabled
								: ""
						}`}
					{...(category.count > 0 ||
					category.type === categories[0].type
						? { onClick: () => setActiveCategory(category.type) }
						: {})}
				>
					{category.icon !== "" && <Icon icon={category.icon} />}
					<span>{category.name}</span>
					{category.count > 0 && category.type != "sve" && (
						<span className={style.count}>{category.count}</span>
					)}
				</SwiperSlide>
			))}
			<div className={`custom-next ${style.nextButton}`}>
				<Icon icon="fa-chevron-right" />
			</div>
			<div className={`custom-prev ${style.prevButton}`}>
				<Icon icon="fa-chevron-left" />
			</div>
		</Swiper>
	);
}

export default SearchFilter;
