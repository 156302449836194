import { useState } from "react";

import style from "./CourseTypeSelect.module.css";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/ui/select/Select";

import Icon from "@/ui/icon/Icon";

function CourseTypeSelect({ selectedCourseType, setSelectedCourseType }) {
	return (
		<Select
			value={selectedCourseType}
			onValueChange={setSelectedCourseType}
		>
			<SelectTrigger>
				<SelectValue placeholder="Tip kursa" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectItem className={style.item} value="all">
						Svi kursevi
					</SelectItem>
					<SelectItem className={style.item} value="video">
						<span>Video</span>
						<Icon icon="fa-chalkboard" />
					</SelectItem>
					<SelectItem className={style.item} value="audio">
						<span>Audio</span>
						<Icon icon="fa-headphones" />
					</SelectItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}

export default CourseTypeSelect;
