import { request } from "@/services/network";
import { cleanReferralCode } from "@/utils/helpers";

function cleanUser(user) {
	const {
		username,
		email,
		first_name: firstName,
		last_name: lastName,
		phone_number: phoneNumber,
		role,
		picture,
		safe_picture_url,
		is_subscribed,
		referral_code,
	} = user;

	return {
		username,
		email,
		firstName,
		lastName,
		phoneNumber,
		role,
		picture,
		safe_picture_url,
		is_subscribed,
		referral_code,
	};
}

export async function whoami() {
	return request({
		url: "/auth/whoami/",
		method: "GET",
	}).then((data) => {
		return {
			isLoggedIn: data.logged_in,
			user: data.user == null ? null : cleanUser(data.user),
		};
	});
}

export async function login({ username, password }) {
	return request({
		url: "/auth/login/",
		method: "POST",
		data: {
			username: username,
			password: password,
		},
	});
}

export async function editSettings({ updatedSettings }) {
	return request({
		url: `/auth/settings/`,
		method: "POST",
		headers: {
			"Content-Type": "multipart/form-data",
		},
		data: {
			username: updatedSettings.username,
			first_name: updatedSettings.first_name,
			last_name: updatedSettings.last_name,
			phone_number: updatedSettings.phone_number,
			picture: updatedSettings.picture ?? null,
			picture_clear: updatedSettings.picture_clear,
		},
	});
}

export async function changePassword({ new_password1, new_password2 }) {
	return request({
		url: `/auth/change-password/`,
		method: "POST",
		data: {
			new_password1,
			new_password2,
		},
	});
}

export async function sendForgotPasswordCode({ email }) {
	return request({
		url: `/auth/forgot-password/send-code/`,
		method: "POST",
		data: {
			email,
		},
	});
}

export async function useForgotPasswordCode({
	code,
	new_password1,
	new_password2,
}) {
	return request({
		url: `/auth/forgot-password/use-code/`,
		method: "POST",
		data: {
			code,
			new_password1,
			new_password2,
		},
	});
}

export async function sendVerificationCode({ email }) {
	return request({
		url: "/auth/register/send-email-code/",
		method: "POST",
		data: {
			email: email,
		},
	});
}

export async function checkVerificationCode({ email, code }) {
	return request({
		url: "/auth/register/check-email-code/",
		method: "POST",
		data: {
			email: email,
			code: code,
		},
	});
}

function getReferralCode() {
	return cleanReferralCode(localStorage.getItem("referral_code"));
}

export async function register({ data }) {
	return request({
		url: `/auth/register/`,
		method: "POST",
		data: {
			email: data.email,
			code: data.code,
			username: data.username,
			first_name: data.first_name,
			last_name: data.last_name,
			phone_number: data.phone_number,
			password1: data.password1,
			password2: data.password2,
			referral_code: getReferralCode(),
		},
	});
}

export async function loginWithGoogleOAuthCode({ code }) {
	return request({
		url: "/auth/login/google/",
		method: "POST",
		data: {
			code: code,
			referral_code: getReferralCode(),
		},
	});
}

export async function sendHelpTicket({
	fullName,
	email,
	phoneNumber,
	contactMethod,
	message,
}) {
	return request({
		url: `/support/`,
		method: "POST",
		data: {
			full_name: fullName,
			email,
			phone_number: phoneNumber,
			contact_method: contactMethod,
			message,
		},
	});
}
