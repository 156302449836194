import style from "./PageNotFound.module.css";
import Container from "@/layouts/container/Container";
import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";
import Logo from "@/ui/logo/Logo";

function PageNotFound() {
	return (
		<Container className={style.page}>
			<div className={style.header}>
				<Logo />
			</div>
			<div className={style.container}>
				<div className={style.info}>
					<span>404 greška</span>
					<h2 className={style.title}>
						Upps! Stranica nije pronađena.
					</h2>
					<p className={style.text}>
						Žao nam je stranica koju tražite ne postoji.
					</p>
					<div className={style.buttonHolder}>
						<Button to="/" type="accent">
							<Icon icon="fa-arrow-left" />
							<span>Vrati se nazad</span>
						</Button>
					</div>
				</div>
				<div className={style.decoration}>
					<div className={style.icoHolder}>
						<div className={style.parent}>
							<span className={style.square}></span>
							<span className={style.circle}></span>
							<span className={style.triangle}></span>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
}

export default PageNotFound;
