import style from "./AskAiButton.module.css";

import Icon from "@/ui/icon/Icon";
import Modal from "@/ui/modal/Modal";
import AiPopup from "@/features/ai/components/aiPopup/AiPopup";
import { useContext } from "react";
import UmamiContext from "@/contexts/UmamiContext";

function AskAiButton({ currentUnit }) {
	const { trackEvent } = useContext(UmamiContext);

	return (
		<Modal>
			<Modal.Open
				opens="aiPopup"
				onClick={() => {
					try {
						trackEvent("ai-open", {
							course: currentUnit?.course?.name,
						});
					} catch (e) {
						console.log("Failed to track event");
					}
				}}
			>
				<div className={style.buttonHolder}>
					<div className={style.button}>
						<span>Pitaj AI</span>
						<Icon icon="fa-sparkles" />
					</div>
				</div>
			</Modal.Open>

			<Modal.Window name="aiPopup" maxWidth="1600px">
				<AiPopup currentUnit={currentUnit} />
			</Modal.Window>
		</Modal>
	);
}

export default AskAiButton;
