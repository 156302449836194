import style from "./PathScheduleAppointment.module.css";

import Modal from "@/ui/modal/Modal";
import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";

import PathScheduleTestPopup from "../pathScheduleTestPopup/PathScheduleTestPopup";

function PathScheduleAppointment() {
	return (
		<Modal>
			<Modal.Open opens="scheduleTest">
				<Button type="accent" disabled>
					<span>Zakaži test</span>
					<Icon icon="fa-calendar-check" />
				</Button>
			</Modal.Open>
			<Modal.Window name="scheduleTest" maxWidth="600px">
				<PathScheduleTestPopup />
			</Modal.Window>
		</Modal>
	);
}

export default PathScheduleAppointment;
