import { useApiGet } from "@/hooks/useApi";
import { getCourseReviews } from "../api";

export function useCourseReviews({ course }) {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["reviews", course.slug],
		fn: () => getCourseReviews({ course }),
	});

	const { reviews, most_liked_video } = data || {
		reviews: [],
		most_liked_video: null,
	};

	return {
		reviews,
		mostLikedVideo: most_liked_video,
		isPending,
		isSuccess,
		isError,
		error,
	};
}
