import { request } from "@/services/network";
import { removeCjelina } from "@/utils/helpers";

export async function getSearchIndex() {
	return request({
		url: "/search/",
		method: "GET",
	}).then((data) => {
		let course_categories = {};
		let course_thumbnails = {};

		for (let course of data?.courses || []) {
			if (course.slug) {
				course_categories[course.slug] = course.category;
				course_thumbnails[course.slug] = course.thumbnail;
			}
		}

		let kursevi = {
			id: 1,
			type: "kursevi",
			name: "Kursevi",
			content: (data?.courses || []).map((course) => {
				return {
					id: course.slug,
					url: "/courses/" + course.slug,
					category: course.category,
					thumbnail: course.thumbnail,
					title: course.name,
					likes: course.like_count,
					modules: course.module_count,
					duration: course.duration,
					lecturers: course.lecturers || [],
				};
			}),
		};

		let videi = {
			id: 2,
			type: "video",
			name: "Videi",
			content: (data?.videos || []).map((video) => {
				return {
					id: [video.course_slug, video.module_slug, video.slug].join(
						"/"
					),
					url: [
						"/courses",
						video.course_slug,
						video.module_slug,
						video.slug,
					].join("/"),
					courseName: video.course_name,
					moduleName: video.module_name,
					category: course_categories[video.course_slug],
					thumbnail: video.thumbnail,
					likes: video.like_count,
					views: video.view_count,
					duration: video.duration,
					title:
						removeCjelina(video.name).length <= 12
							? `${removeCjelina(video.name)} - ${removeCjelina(
									video.course_name
							  )}`
							: removeCjelina(video.name),
				};
			}),
		};

		let kvizovi = {
			id: 3,
			type: "kvizovi",
			name: "Kvizovi",
			content: (data?.quizzes || []).map((quiz) => {
				return {
					id: [quiz.course_slug, quiz.module_slug, quiz.slug].join(
						"/"
					),
					url: [
						"/courses",
						quiz.course_slug,
						quiz.module_slug,
						quiz.slug,
					].join("/"),
					category: course_categories[quiz.course_slug],
					title:
						removeCjelina(quiz.name).length <= 12
							? `${removeCjelina(quiz.name)} - ${removeCjelina(
									quiz.module_name
							  )}`
							: removeCjelina(quiz.name),
					questions: quiz.question_count,
					thumbnail: course_thumbnails[quiz.course_slug],
					courseName: quiz.course_name,
					moduleName: quiz.module_name,
				};
			}),
		};

		let paths = {
			id: 4,
			type: "path",
			name: "Putanje",
			content: (data?.paths || []).map((path) => {
				return {
					id: path.slug,
					url: `/paths/${path.slug}`,
					category: path.category || null,
					title: path.name,
					slug: path.slug,
					certificateId: path.certificate_id,
					thumbnail: path.thumbnail,
					ectsCredits: path.ects_credits,
					courseCount: path.course_count,
					priority: path.priority,
				};
			}),
		};

		return [kursevi, videi, kvizovi, paths];
	});
}
