import { useEffect, useState, useRef } from "react";
import style from "./PathCoursesProgressHolder.module.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Mousewheel } from "swiper/modules";

import CoruseProgressVerticalCard from "@/features/courses/components/courseProgressVerticalCard/CoruseProgressVerticalCard";

function PathCoursesProgressHolder({ courses }) {
	const [activeIndex, setActiveIndex] = useState(2); // Initial active index
	const swiperRef = useRef(null);

	useEffect(() => {
		if (swiperRef.current?.swiper) {
			swiperRef.current.swiper.slideTo(activeIndex, 300);
		}
	}, [activeIndex]);

	const handleSlideChange = () => {
		if (swiperRef.current?.swiper) {
			setActiveIndex(swiperRef.current.swiper.activeIndex);
		}
	};

	return (
		<Swiper
			ref={swiperRef}
			direction="horizontal"
			slidesPerView="auto"
			centeredSlides={true}
			onSlideChange={handleSlideChange}
			initialSlide={activeIndex}
			pagination={{ clickable: true }}
			scrollbar={{
				draggable: true,
			}}
			lazyPreloadPrevNext={1}
			keyboard={{
				enabled: true,
				onlyInViewport: true,
			}}
			mousewheel={{
				forceToAxis: true,
			}}
			modules={[Mousewheel, Keyboard]}
			className={style.cards}
		>
			{courses.map((course, index) => (
				<SwiperSlide key={course.slug} className={style.swiperItem}>
					<CoruseProgressVerticalCard
						course={course}
						active={index === activeIndex}
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
}

export default PathCoursesProgressHolder;
