import style from "./PathProgressBanner.module.css";

import PathProgressBar from "../pathProgressBar/PathProgressBar";
import PathScheduleAppointment from "../pathScheduleAppointment/PathScheduleAppointment";
import PathCoursesProgressHolder from "../pathCoursesProgressHolder/PathCoursesProgressHolder";

import CoruseProgressVerticalCard from "@/features/courses/components/courseProgressVerticalCard/CoruseProgressVerticalCard";
import { useGetMostLikedCourses } from "@/features/courses/hooks/useGetMostLikedCourses";

function PathProgressBanner() {
	const { data: courses, isPending, isError } = useGetMostLikedCourses();

	if (isPending) return <div>Loading...</div>;
	if (isError) return <div>Error...</div>;

	return (
		<div className={style.banner}>
			<div className={style.pathContent}>
				<div className={style.header}>
					<div>
						<h2>Soft Skills Masterclass - tvoja formula uspjeha</h2>
					</div>
					<div className={style.buttonHolder}>
						<PathScheduleAppointment />
					</div>
				</div>
				<div className={style.body}>
					<PathProgressBar currentCourse={11} coursesInPath={14} />
				</div>
			</div>
			<div className={style.coursesContent}>
				<PathCoursesProgressHolder courses={courses} />
			</div>
		</div>
	);
}

export default PathProgressBanner;
