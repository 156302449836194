import style from "./CourseProgressBanner.module.css";
import { Link } from "react-router-dom";
import VideoBreadcrumb from "@/ui/videoBreadcrumb/VideoBreadcrumb";
import Button from "@/ui/button/Button";
import Icon from "@/ui/icon/Icon";
import ContinueWatchingStatistics from "@/features/courses/components/continueWatchingStatistics/ContinueWatchingStatistics";

function videoPercentComplete(video) {
	if (video?.user_activity?.current_second_in_video && video?.duration > 0) {
		return Math.floor(
			(100 * video.user_activity.current_second_in_video) / video.duration
		);
	}
	return 0;
}

function CourseProgressBanner({ courseData }) {
	const video = courseData?.video;

	if (!video) {
		return null;
	}

	return (
		<div className={style.banner}>
			<div className={style.left}>
				<h2>NASTAVI GLEDATI:</h2>
				<h1>{video.name}</h1>
				<p>{video.description || video.module.description}</p>
				<div className={style.buttonHolder}>
					<Button
						to={`/courses/${video.module.course.slug}/${video.module.slug}/${video.slug}/`}
						type="accent"
					>
						<span>Nastavi gledati</span>
						<Icon icon="fa-arrow-right-long" />
					</Button>
				</div>
			</div>
			<div className={style.right}>
				<div className={style.videoInfo}>
					<VideoBreadcrumb video={video} />
					<Link
						to={`/courses/${video.module.course.slug}/${video.module.slug}/${video.slug}/`}
						style={{
							"--background": `url(${video.thumbnail})`,
							"--video-percent": `${videoPercentComplete(
								video
							)}%`,
						}}
						className={style.video}
					></Link>
				</div>
				<ContinueWatchingStatistics
					courseProgress={courseData.courseProgress}
				/>
			</div>
		</div>
	);
}

export default CourseProgressBanner;
