import { useApiGet } from "@/hooks/useApi";
import { getMostLikedCourses } from "../api";

export function useGetMostLikedCourses() {
	const { data, isPending, isSuccess, isError, error } = useApiGet({
		key: ["courses", "list", "most-liked"],
		fn: getMostLikedCourses,
	});

	const { courses } = data || { courses: [] };

	return { data: courses, isPending, isSuccess, isError, error };
}
