import style from "./PathDetails.module.css";
import Icon from "@/ui/icon/Icon";
import Button from "@/ui/button/Button";
import { secondsToHoursMinutes } from "@/utils/helpers";

import PathScheduleTestPopup from "@/features/paths/components/pathScheduleTestPopup/PathScheduleTestPopup";

import { useContext } from "react";
import PathPreviewContext from "../../contexts/PathPreviewContext";
import { useNavigate } from "react-router-dom";
import { successToast, warningToast } from "@/ui/toast/Toast";
import { useUser } from "@/features/auth/hooks/useUser";
import PathScheduleAppointment from "../pathScheduleAppointment/PathScheduleAppointment";

function PathDetails() {
	const { path } = useContext(PathPreviewContext);
	const { user } = useUser();
	const navigate = useNavigate();

	const details = [
		{
			id: 3,
			icon: "fa-fingerprint",
			label: "Broj certifikata",
			value: path.certificate_id,
		},
		{
			id: 1,
			icon: "fa-layer-group",
			label: "Broj cjelina",
			value: path.module_count,
		},
		{
			id: 2,
			icon: "fa-question",
			label: "Broj kvizova",
			value: path.quiz_count,
		},
		{
			id: 4,
			icon: "fa-user",
			label: "Broj predavača",
			value: path.lecturers.length,
		},
		{
			id: 6,
			icon: "fa-star",
			label: "Recenzije",
			value: path.rating,
		},

		{
			id: 5,
			icon: "fa-align-left",
			label: "Silabus",
			value: "Preuzmi silabus",
		},

		{
			id: 7,
			icon: "fa-file",
			label: "Projekti",
			value: "Preuzmi projkat",
		},
	];

	return (
		<div className={style.pathDetails}>
			<div className={style.pointsHolder}>
				<span className={style.label}>Ovaj Kurs Vam donosi</span>
				<div className={style.points}>
					<span className={style.value}>{path.ects_credits}</span>
					<span className={style.valute}>
						<span>ECTS</span>
						<span>bodova</span>
					</span>
				</div>
			</div>
			<div className={style.infoHolder}>
				<div className={style.item}>
					<div className={style.icoHolder}>
						<Icon icon="fa-chalkboard" />
					</div>
					<div className={style.content}>
						<div className={style.label}>Kurseva</div>
						<div className={style.value}>{path.course_count}</div>
					</div>
				</div>
				<div className={style.item}>
					<div className={style.icoHolder}>
						<Icon icon="fa-clock" />
					</div>
					<div className={style.content}>
						<div className={style.label}>Trajanje</div>
						<div className={style.value}>
							{secondsToHoursMinutes(path.duration)}
						</div>
					</div>
				</div>
			</div>
			<div className={style.details}>
				{details.map((detail) => (
					<div key={detail.id} className={style.detail}>
						<div className={style.icoHolder}>
							<Icon icon={detail.icon} />
						</div>
						<div className={style.label}>{detail.label}:</div>
						<div className={style.value}>
							{detail.label == "Silabus" ||
							detail.label == "Projekti" ? (
								<div className={style.downloadable}>
									{detail.value}
								</div>
							) : (
								detail.value
							)}
						</div>
					</div>
				))}
			</div>
			<div className={style.buttonsHolder}>
				<Button
					type="accent"
					onClick={() => {
						if (!user || !user?.is_subscribed) {
							warningToast(
								"Pretplatite se da biste započeli putanju učenja"
							);
							navigate("/plans");
							return;
						}
						if (!path.courses.length) return;
						navigate(`/courses/${path.courses[0].slug}`);
						successToast("Uspješno ste započeli putanju učenja!");
					}}
				>
					<span>Započni putanju</span>{" "}
					<Icon icon="fa-arrow-right" iconType="fa-solid" />
				</Button>
				<PathScheduleAppointment />
			</div>
		</div>
	);
}

export default PathDetails;
