import React, { useState } from "react";
import style from "./Home.module.css";

import Container from "@/layouts/container/Container";
import IncompleteCourses from "@/features/courses/components/incompleteCourses/IncompleteCourses";
import CourseSwiper from "@/features/courses/components/courseSwiper/CourseSwiper";
import CourseProgressBanner from "@/features/courses/components/courseProgressBanner/CourseProgressBanner";
import PathProgressBanner from "@/features/paths/components/pathProgressBanner/PathProgressBanner";

import { useGetRecentCourses } from "@/features/courses/hooks/useGetRecentCourses";
import { useGetContinueWatching } from "@/features/courses/hooks/useGetContinueWatching";
import { useGetMostLikedCourses } from "@/features/courses/hooks/useGetMostLikedCourses";

import FullPageLoader from "@/ui/fullPageLoader/FullPageLoader";
import Error from "@/ui/error/Error";

function Home() {
	const [yes, setYes] = useState(true);
	const {
		data: currentCourse,
		isError: currentError,
		isPending: currentPending,
	} = useGetContinueWatching();
	const {
		data: recentCourses,
		isError: recentError,
		isPending: recentPending,
	} = useGetRecentCourses();
	const {
		data: mostLikedCourses,
		isError: likedError,
		isPending: likedPending,
	} = useGetMostLikedCourses();

	const hasError = currentError || recentError || likedError;
	const isLoading = currentPending || recentPending || likedPending;

	if (hasError) return <Error />;
	if (isLoading) return <FullPageLoader />;

	return (
		<div className={style.page}>
			<div className={style.background}>
				<Container>
					{yes ? (
						<CourseProgressBanner courseData={currentCourse} />
					) : (
						<PathProgressBanner />
					)}
				</Container>
			</div>

			<Container>
				<CourseSwiper title="Skoro dodano" courses={recentCourses} />
				<IncompleteCourses />
				<CourseSwiper title="Najgledanije" courses={mostLikedCourses} />
			</Container>
			<button
				className="absolute bottom-1 right-1 w-1 h-1 bg-yellow-500"
				onClick={() => setYes(!yes)}
			></button>
		</div>
	);
}
``;

export default Home;
