import { useUser } from "@/features/auth/hooks/useUser";
import { UMAMI_SCRIPT_URL, UMAMI_WEBSITE_ID } from "@/utils/constants";
import { cleanReferralCode } from "@/utils/helpers";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const setupUmami = () => {
	const [umamiLoaded, setUmamiLoaded] = useState(false);
	const { isLoggedIn, user, isPending } = useUser();
	const location = useLocation();
	const umami = window.umami;
	const umamiReady = umamiLoaded && !isPending;

	// Update referral code in localstorage whenever location changes
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		if (params.get("r"))
			localStorage.setItem(
				"referral_code",
				cleanReferralCode(params.get("r"))
			);
	}, [location]);

	const getReferralCode = useCallback(() => {
		if (localStorage.getItem("referral_code"))
			return cleanReferralCode(localStorage.getItem("referral_code"));
		if (user && user?.referral_code)
			return cleanReferralCode(user.referral_code);
		return null;
	}, [user]);

	const referralCode = getReferralCode();

	const extraProps = {
		is_logged_in: isLoggedIn,
		is_subscribed: user?.is_subscribed,
		is_admin: user?.role == "admin",
		referral_code: referralCode,
		tag: [
			isLoggedIn ? "is_auth" : "not_auth",
			user?.is_subscribed ? "is_sub" : "not_sub",
			user?.role == "admin" ? "is_admin" : "not_admin",
			referralCode ? `r_${referralCode}` : "r_none",
		]
			.join(" ")
			.trim(),
		url: `${location.pathname}${location.search}${location.hash}`,
		title: "Dekamas", // always save Dekamas - otherwise it can have the old title unless we do a long delay
	};

	const trackPageView = useCallback(
		(data) => {
			// Ideally should always wait for umamiReady before calling this function
			if (!umamiReady) return;

			umami.track((props) => ({
				...props,
				...extraProps,
				...(data || {}),
			}));
		},
		[umamiReady, extraProps]
	);

	const trackEvent = useCallback(
		(eventName, data) => {
			if (!umamiReady) return;

			umami.track((props) => ({
				...props,
				...extraProps,
				name: eventName,
				data: { referral_code: getReferralCode(), ...(data || {}) },
			}));
		},
		[umamiReady, extraProps]
	);

	// Setup the script
	useEffect(() => {
		if (UMAMI_SCRIPT_URL && UMAMI_WEBSITE_ID) {
			if (document.getElementById("umami_tracking_script")) return;

			const head = document.getElementsByTagName("head")[0];
			const script = document.createElement("script");
			script.type = "text/javascript";
			script.src = UMAMI_SCRIPT_URL;
			script.async = "async";
			script.defer = "defer";
			script.setAttribute("id", "umami_tracking_script");
			script.setAttribute("data-website-id", UMAMI_WEBSITE_ID);
			script.setAttribute("data-auto-track", "false");
			head.appendChild(script);

			script.addEventListener("load", function () {
				setUmamiLoaded(true);
			});
		}
	}, []);

	// Identify
	useEffect(() => {
		// only set logged in to true - never revert
		if (!isLoggedIn || !umamiLoaded || isPending) return;

		umami.identify({
			username: user?.username,
			name:
				user?.firstName && user?.lastName
					? `${user?.firstName} ${user?.lastName}`
					: user?.username,
			email: user?.email,
			is_admin: user?.role == "admin",
			is_logged_in: isLoggedIn,
			is_subscribed: user?.is_subscribed || false,
			referral_code: getReferralCode(),
		});
	}, [isLoggedIn, user, umamiLoaded, isPending]);

	// Track page views
	useEffect(() => {
		if (!umamiReady) return;
		trackPageView();
	}, [location, umamiReady]);

	return { umamiReady, umami, trackEvent, trackPageView };
};
