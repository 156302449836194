import { useState, useEffect } from "react";

import style from "./SearchContent.module.css";

import SearchFilter from "../searchFilter/SearchFilter";
import SearchGroup from "../searchGroup/SearchGroup";
import SearchControls from "../searchControls/SearchControls";

import { Command } from "cmdk";

function SearchContent({ searchTerm, searchResults }) {
	const [activeCategory, setActiveCategory] = useState("sve");

	return (
		<Command.List>
			<div className={style.content}>
				<SearchFilter
					searchResults={searchResults}
					activeCategory={activeCategory}
					setActiveCategory={setActiveCategory}
				/>

				<div className={style.groups}>
					{searchResults
						.filter((group) => {
							if (activeCategory != "sve")
								if (group.type != activeCategory) return false;
							return group.content.length > 0;
						})
						.map((group) => (
							<SearchGroup
								key={group.id}
								type={group.type}
								name={group.name}
								content={
									activeCategory == "sve"
										? group.content.slice(0, 3)
										: group.content.slice(0, 9)
								}
								searchTerm={searchTerm}
							/>
						))}
				</div>

				<SearchControls />
			</div>
		</Command.List>
	);
}

export default SearchContent;
