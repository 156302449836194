import React, { useState } from "react";
import style from "./CourseSwiper.module.css";
import Icon from "@/ui/icon/Icon";
import "swiper/css";

import { v4 as uuidv4 } from "uuid";

import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation, Mousewheel } from "swiper/modules";

import CourseCard from "../courseCard/CourseCard";

function CourseSwiper({ title, courses }) {
	const [previousButtonID] = useState("button-" + uuidv4());
	const [nextButtonID] = useState("button-" + uuidv4());

	return (
		<div className={style.coursesHolder}>
			<div className={style.header}>
				<h2>{title}</h2>
				<div className={style.navigation}>
					<div
						className={`custom-prev ${style.navButton} ${style.prev}`}
						id={previousButtonID}
					>
						<Icon icon="fa-arrow-left" />
					</div>
					<span className={style.sep}></span>
					<div
						className={`custom-next ${style.navButton} ${style.next}`}
						id={nextButtonID}
					>
						<Icon icon="fa-arrow-right" />
					</div>
				</div>
			</div>
			<Swiper
				direction="horizontal"
				slidesPerView="auto"
				pagination={{ clickable: true }}
				scrollbar={{
					draggable: true,
				}}
				lazyPreloadPrevNext={1}
				keyboard={{
					enabled: true,
					onlyInViewport: true,
				}}
				navigation={{
					nextEl: `#${nextButtonID}`,
					prevEl: `#${previousButtonID}`,
				}}
				mousewheel={{
					forceToAxis: true,
				}}
				modules={[Mousewheel, Keyboard, Navigation]}
				className={style.body}
			>
				{courses.map((course) => (
					<SwiperSlide key={course.slug} className={style.swiperItem}>
						<CourseCard course={course} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}

export default CourseSwiper;
