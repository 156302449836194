import { createContext } from "react";

const UmamiContext = createContext({
	umamiReady: false,
	umami: null,
	trackEvent: () => {
		console.log("Umami not ready!");
	},
	trackPageView: () => {
		console.log("Umami not ready!");
	},
});

export default UmamiContext;
