import style from "./Header.module.css";

import Logo from "@/ui/logo/Logo";
import Navigation from "@/ui/navigation/Navigation";
import Button from "@/ui/button/Button";

import Icon from "@/ui/icon/Icon";
import UserProfile from "@/ui/userProfile/userProfile";

import { useUser } from "@/features/auth/hooks/useUser";

import SearchParent from "@/features/search/components/searchParent/SearchParent";

function Header() {
	const { isLoggedIn, isLoading } = useUser();

	return (
		<header className={style.header}>
			<div className={style.nav}>
				<Logo />
				<Navigation />
			</div>
			<div className={style.searchHolder}>
				<SearchParent />
			</div>
			<div className={style.profileHolder}>
				{!isLoading && (isLoggedIn ? <UserProfile /> : <NotLoggedIn />)}
			</div>
		</header>
	);
}

function NotLoggedIn() {
	return (
		<div className={style.notLoggedIn}>
			<Button to="login" type="none">
				Prijavite se
			</Button>
			<Button to="register" type="border">
				Registrujte se
				<Icon icon="fa-arrow-up-right" />
			</Button>
		</div>
	);
}

export default Header;
